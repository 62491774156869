<template>
  <el-dialog
    title="邀请详情"
    width="900px"
    @open="init"
    :visible.sync="openDialog"
  >
    <div class="list-page">
      <list-filter v-model="filterForm" :uploadFilter="ok" :export_ids="selectArr" :id="id" :page="pageData.current_page"  :jobList="[]" />
      <div class="list-content" v-loading="loading">
        <el-table
          :data="tbody"
          class="thead-light"
          stripe
          style="width: 100%"
          height="400"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed
            type="selection"
            min-width="55"
          ></el-table-column>
          <template v-for="(th, index) in thead">
            <!-- 头像 -->
            <el-table-column
              show-overflow-tooltip
              :prop="th.prop"
              :label="th.label"
              :min-width="th.minWidth || ''"
              :sortable="th.sortable"
              :key="index"
              ><template slot-scope="{ row }">
                <list-image v-if="th.prop === 'avatar'"
                fit="cover" :height="40" :width="40" border-radius="50%"
                :src="row[th.prop]"/>
                <!-- 其他 -->
                <span v-else>{{ row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      />
    </div>
  </el-dialog>
</template>

<script>
import listFilter from './ListFilter.vue'
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'
import Pagination from '@/base/components/Default/Pagination'
import ListImage from '@/base/components/List/ListImage'
import ListStatusText from '@/base/components/List/ListStatusText'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import {
  shareRecords,
  getOrganizationsTree,
} from '../../../api/activity-list/share-records'
export default {
  name: 'ShareRecordsDialog',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: 'ID', prop: 'user_id', minWidth: 100 },
        { label: '头像', prop: 'avatar', minWidth: 80 },
        { label: '会员名称', prop: 'nickname', minWidth: 100 },
        { label: '手机号', prop: 'phone', minWidth: 100 },
        {
          label: '会内职务',
          prop: 'join_organization_name',
          minWidth: 100,
        },
        {
          label: '邀请次数',
          prop: 'total_share_count',
          minWidth: 100,
        },
      ],
      //筛选对象
      filterForm: {
        nickname: '',
        phone: '',
        organization_id: '',
        count_type: -1,
      },

      // 职务列表
      jobList: [],
      pageData: {
        page_size: 15,
        current_page: 1,
      },
      tbody: [],
      selectArr:[]
    }
  },

  methods: {
     // 勾选操作
     handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.user_id)
        })
      }
    },
    // 获取
    getShareRecords(pageData) {
      this.tbody = []
      this.pageData = {}
      this.loading = true

      shareRecords({ ...pageData, id: this.id })
        .then((res) => {
          const { data } = res
          this.tbody = data.data
          this.pageData = data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
   
    // 筛选回调
    ok(e) {
      const pageData = {}
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.getShareRecords({ ...this.filterForm, ...pageData })
    },
    // 刷新列表
    reflashList2() {
      this.getShareRecords({
        page: this.pageData.current_page,
        ...this.filterForm,
      })
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size
      const pageData = { ...this.filterForm, ...e }
      this.getShareRecords(pageData)
    },
    init() {
      getOrganizationsTree({
        is_all: 1,
        is_show_none: 0,
        hashid_enable: 1,
      }).then((res) => {
        this.jobList = res.data
      })
      this.getShareRecords(this.filterForm)
    },
  },

  computed: {
    openDialog: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('close', val)
      },
    },
  },
  components: {
    listFilter,
    Pagination,
    PopoverInputEdit,
    ListImage,
    ListStatusText,
    SingleMediaWall,
  },
}
</script>

<style lang="scss" scoped>
.list-icon {
  width: 60px;
  height: 60px;
}
</style>
