<template>
  <el-dialog title="取消活动" :visible.sync="openDialog" width="537px" :show-close="true" class="dialog-vertical"
    @close="cancel('cancelReason')">
    <el-form size="small" :model="cancelReason" :rules="rules" ref="cancelReason" label-width="100px" @submit.native.prevent>
      <el-form-item label="取消原因" prop="text">
        <el-input type="textarea" v-model="cancelReason.text" :autosize="{ minRows: 4 }" maxlength="20"
          show-word-limit style="width: 90%" placeholder="请填写20字以内的描述">
        </el-input>
      </el-form-item>
      <!-- <el-form-item prop="image">
        <image-wall v-model="cancelReason.image" :width="194" :height="135"></image-wall>
      </el-form-item> -->
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('cancelReason')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { CancelActivity } from "../../api/activity-list/list";
import ImageWall from "@/modules/common/components/SingleMediaWall.vue";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    ID: {
      type: [Number, String],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      // 取消原因
      cancelReason: {
        text: "",
        image: "",
      },
      rules: {
        text: [{ required: true, message: "请填写取消原因", trigger: "blur" }],
      },
      PositionCheck: "",
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            id: this.id,
            cancel_reason: JSON.stringify({ ...this.cancelReason }),
          };
          CancelActivity(data)
            .then((res) => {
              this.$refs[formName].resetFields();
              this.$message.success(res.msg);
              this.openDialog = false;
              this.updateList();
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  watch: {
    IDs(val) {
      this.cancelReason.id = val;
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    id() {
      return this.ID;
    },
  },
  components: {
    ImageWall,
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
