<template>
  <div class="list-page">
    <el-button
      icon="el-icon-sort"
      class="top-btn"
      :type="isNewEdition ? 'default' : 'primary'"
      size="small"
      @click="toggleEdition"
      >切换{{ !isNewEdition ? '新版' : '旧版' }}活动详情</el-button
    >
    <el-button
      class="top-btn"
      type="primary"
      size="small"
      @click="handleAddActivity"
      >新增</el-button
    >
    <list-filter
      :filterForm="filterForm"
      :ids="selectArr"
      :page="pageData.current_page"
      :uploadFilter="ok"
      @categories="onCategories"
    />
    <list-tabs
      v-model="filterForm.tab"
      :isLoading="loading"
      :tabs="tabArr"
      @tab-click="handleClick"
    ></list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab === 'normal'">
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
        <el-button
          v-if="categoryOptions.length"
          type="info"
          size="small"
          @click="moveTo"
          >转移分类</el-button
        >
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button type="success" size="small" @click="handleReduct"
          >还原</el-button
        >
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="min-width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选-->
        <el-table-column
          fixed
          type="selection"
          min-width="55"
        ></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :min-width="filterForm.tab === 'normal' ? '350' : '170'"
          fixed="right"
          align="right"
        >
          <template slot-scope="scope">
            <!-- 回收站 -->
            <div v-if="filterForm.tab == 'deleted'">
              <el-button type="text" size="small" @click="editRow(scope.row)"
                >查看</el-button
              >
              <el-divider direction="vertical" />
              <el-dropdown @command="handleClickRow($event, scope.row)">
                <span class="el-dropdown-link">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in moreHandleList.deleted"
                    :key="index"
                    :command="item.command"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 全部 -->
            <div v-if="filterForm.tab == 'normal'">
              <el-button type="text" size="small" @click="editRow(scope.row)"
                >编辑</el-button
              >

              <el-button
                v-if="scope.row.create_step && isNewEdition"
                type="text"
                size="small"
                @click="postRow(scope.row)"
                >发布</el-button
              >
              <el-button
                v-if="!scope.row.create_step && isNewEdition"
                type="text"
                size="small"
                @click="withDrawRow(scope.row)"
                >撤回</el-button
              >
              <el-button type="text" size="small" @click="copyRow(scope.row)"
                >复制</el-button
              >

              <el-button
                type="text"
                size="small"
                :disabled="scope.row.type === 0"
                @click="handleClickRow('SignUpList', scope.row)"
                ><el-badge
                  :hidden="!(scope.row.checking_users_count > 0)"
                  :value="scope.row.checking_users_count"
                  >报名名单
                </el-badge>
              </el-button>

              <el-button
                type="text"
                size="small"
                @click="handleClickRow('ActivityPostList', scope.row)"
                >发帖列表</el-button
              >
              <el-divider direction="vertical" />
              <el-dropdown @command="handleClickRow($event, scope.row)">
                <span class="el-dropdown-link">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <template v-if="scope.row.create_step == 0">
                    <el-dropdown-item
                      v-for="(item, index) in moreHandleList.normal.filter(
                        (n) => n.visible === undefined || n.visible()
                      )"
                      :key="index"
                      :command="item.command"
                    >
                      <!-- 线下活动才有报名名单 -->
                      <template v-if="item.name === '报名名单'">
                        <span v-if="scope.row.type"> {{ item.name }}</span>
                      </template>
                      <!-- 线下活动才有排位信息 -->
                      <template v-else-if="item.name === '排位信息'">
                        <span v-if="scope.row.type"> {{ item.name }}</span>
                      </template>
                      <!-- 线下活动才有签到码 -->
                      <template v-else-if="item.name === '签到码'">
                        <span v-if="scope.row.type"> {{ item.name }}</span>
                      </template>
                      <template v-else>
                        {{ item.name }}
                      </template>
                    </el-dropdown-item>
                  </template>

                  <el-dropdown-item
                    v-if="
                      scope.row.activity_status_text_two === 2 ||
                      scope.row.activity_status_text_two === 5 ||
                      scope.row.activity_status_text_two === 6
                    "
                    command="CancelActivity"
                    >取消活动</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="scope.row.activity_status_text_two === 1"
                    command="recoverActivity"
                    >恢复活动</el-dropdown-item
                  >
                  <el-dropdown-item command="deleteRow">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <!-- 推荐 -->
              <el-checkbox
                v-if="['is_recommend'].includes(th.prop)"
                v-model="scope.row[th.prop]"
                :true-label="1"
                :false-label="0"
                @change="changeStatus(scope.row, th.prop)"
              />
              <!-- 显示 -->
              <el-checkbox
                v-else-if="['is_show'].includes(th.prop)"
                v-model="scope.row[th.prop]"
                :true-label="1"
                :false-label="0"
                @change="changeStatus(scope.row, th.prop)"
                :disabled="scope.row.create_step !== 0"
              />
              <!-- 所属组织-->
              <span v-else-if="th.prop === 'brands'">{{
                scope.row[th.prop].join('、') | placeholder
              }}</span>
              <!-- 活动状态 -->
              <span v-else-if="th.prop === 'activity_status_text'">
                <span
                  :class="[
                    'status-dot',
                    scope.row.activity_status_text_two === 3
                      ? 'green'
                      : scope.row.activity_status_text_two === 1 ||
                        scope.row.activity_status_text_two === 4
                      ? 'grey'
                      : 'yellow',
                  ]"
                ></span>
                {{ scope.row[th.prop] }}
              </span>
              <!-- 排序 -->
              <PopoverInputEdit
                v-else-if="th.prop === 'sort'"
                v-model="scope.row.sort"
                input-type="number"
                :validator="validator"
                @confirm="changeStatus(scope.row, th.prop)"
              ></PopoverInputEdit>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <CancelActivity
      v-model="openCancelDialog"
      :ID="currentActivityId"
      :updateList="reflashList"
    />
    <!-- 签到码弹窗 -->
    <ActivityConfig
      :showCode="showCode"
      :activity_id="currentActivityId"
      :status="0"
      @closeCode="showCode = false"
    ></ActivityConfig>
    <!-- 活动列表弹窗 -->
    <ShareRecordsDialog
      :show="ShareRecordsDialogShow"
      :id="currentActivityId"
      @close="ShareRecordsDialogShow = false"
    />
    <activity-category-move-dialog
      v-if="moveDialog.data"
      v-model="moveDialog.show"
      :data="moveDialog.data"
      :category-options="categoryOptions"
      :updateData.sync="moveDialog.data"
      @submit="doMoveTo"
    />
  </div>
</template>

<script>
import listFilter from '../../components/ActivityList/ListFilter'
import { getCircleTopic } from '../../api/activity-post/list'
import { getActivity } from '../../api/activity-list/detail'

import Pagination from '@/base/components/Default/Pagination'
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'
// 签到码弹窗
import ActivityConfig from '../../components/ActivityConfig/ActivityConfig'
import ShareRecordsDialog from '../../components/ActivityList/ShareRecordsDialog/ShareRecordsDialog'
import {
  ActivityList,
  softDelete,
  Delete,
  putBack,
  updateRecommend,
  updateSort,
  updateShow,
  moveMany,
  publish,
  withdraw,
  copyActivityInfo,
} from '../../api/activity-list/list'
import CancelActivity from '../../components/ActivityList/CancelActivity.vue'
import query from '../../../../base/mixins/query'
import ActivityCategoryMoveDialog from '../../components/ActivityList/List/MoveDialog'
export default {
  mixins: [query],
  data() {
    return {
      tabArr: [
        { label: '全部', name: 'normal', total: 0 },
        { label: '回收站', name: 'deleted', total: 0 },
      ],
      loading: false, //加载
      thead: [
        { label: '活动ID', prop: 'id', minWidth: 80 },
        { label: '活动名称', prop: 'name', minWidth: 150 },
        {
          label: '报名人数',
          prop: 'join_count_text',
          minWidth: 100,
        },
        {
          label: '报名类型',
          prop: 'signup_type_text',
          minWidth: 100,
        },
        { label: '活动时间', prop: 'activity_time_text', minWidth: 290 },
        {
          label: '所属架构',
          prop: 'organization_name',
          minWidth: 150,
        },
        // {
        //   label: "所属组织",
        //   prop: "brands",
        //   minWidth: 150,
        // },
        { label: '活动状态', prop: 'activity_status_text', minWidth: 100 },
        { label: '活动分类', prop: 'category_names', minWidth: 100 },
        { label: '活动类型', prop: 'activity_type_text', minWidth: 100 },
        { label: '是否发布', prop: 'publish_status_text', minWidth: 100 },

        // { label: '创建者', prop: 'username', minWidth: 100 },
        { label: '排序', prop: 'sort', minWidth: 60 },
        { label: '显示', prop: 'is_show', minWidth: 60 },
        { label: '推荐', prop: 'is_recommend', minWidth: 60 },
        // { label: "显示", prop: "is_show", minWidth: 60 },
      ],
      //筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        user_keyword: '', //用户名
        activity_keyword: '', // 活动名称
        category_id: '',
        // brand_id: -1, //品牌Id
        status: -1, // 活动状态
        type: -1, // 活动类型
        sign_type: -1, //	报名类型
        tab: 'normal', //当前筛选tab
        page_size: 15, //每页多少条数据
        organization_id: -1, //所属架构
        page: 1,
      },
      //筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      moreHandleList: {
        normal: [
          // { name: "图文互动", command: "ImageText" },
          // { name: "图文列表", command: "InteractionList" },
          // { name: "报名名单", command: "SignUpList" },
          // { name: "活动配置", command: "ListConfig" },
          // { name: "心得列表", command: "ExperienceList" },
          // { name: "签到码", command: "SignCode" },

          { name: '图文直播', command: 'ImageText' },
          // { name: '报名名单', command: 'SignUpList' },
          { name: '排位信息', command: 'Ranking' },
          // { name: "排位信息", command: "ListConfig" },
          {
            name: '关联内容',
            command: 'Information',
            visible: () => this.isCycOrganization,
          },
          // { name: '发帖列表', command: 'ActivityPostList', visible: () => this.isCircle },
          {
            name: '评论管理',
            command: 'SetComments',
            visible: () => this.isCycOrganization,
          },
          // { name: "数据统计", command: "SignCode" },
          { name: '签到码', command: 'SignCode' },
          {
            name: '活动标签',
            command: 'ActivityTag',
            visible: () => this.isCycOrganization,
          },
          { name: '邀请详情', command: 'ShareRecords' },
        ],
        deleted: [
          { name: '还原', command: 'ReductRow' },
          { name: '删除', command: 'deleteRow' },
        ],
      },
      pageData: {
        page_size: 15,
        tab: 'normal',
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      /* 行内编辑数字验证 */
      validator: {
        type: 'integer',
        min: 0,
        message: '最小不能低于0的整数',
        trigger: 'blur',
      },
      currentActivityId: 0, //当前操作的活动id
      openCancelDialog: false, //取消活动弹窗
      // 下载签到码弹窗
      showCode: false,
      // 邀请详情弹窗
      ShareRecordsDialogShow: false,

      categoryOptions: [],
      moveDialog: {
        show: false,
        data: null,
      },
    }
  },
  computed: {
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
    isCircle() {
      return this.$store.getters.tenant.features.includes('circle')
    },
    // 是否是新版本活动详情
    isNewEdition: {
      get() {
        return this.$store.state.activityList.isNewEdition
      },
      set(val) {
        // this.$store.
        //相同路由进行刷新
        this.$store.commit('activityList/setIsNewEdition', val)
      },
    },
  },
  methods: {
    onCategories(arr) {
      this.categoryOptions = arr
    },
    doMoveTo({ ids: category_ids = [] }) {
      this.loading = true

      moveMany({
        // "category_ids":JSON.stringify(category_ids),
        // "activity_ids": JSON.stringify(this.selectArr)
        category_ids: category_ids,
        activity_ids: this.selectArr,
      })
        .then(({ code, msg }) => {
          if (code) {
            setTimeout(() => {
              this.$message.success(msg)
              this.reflashList()
            }, 1500)
          } else {
            this.$message.error(msg)
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    /**
     * 批量添加到新分类
     */
    moveTo() {
      if (this.selectArr.length) {
        this.moveDialog.data = { ids: [] } // 多选的分类
        this.moveDialog.show = true
      } else {
        this.$message.info('请选择数据项')
      }
    },
    getList(q) {
      this.getActivityList(q)
    },
    // 获取活动列表
    getActivityList(pageData) {
      // this.tbody = [];
      this.pageData = {}
      this.loading = true
      ActivityList(pageData)
        .then((res) => {
          const { data } = res
          // this.exchangeParentList(data.data);
          this.tbody = data.data
          this.pageData = data
          // this.filters[data.tab] = { ...this.filterForm };

          const tab = this.tabArr.find((t) => t.name === pageData.tab)
          tab.total = data.total

          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 所属组织格式处理
    exchangeParentList(data) {
      data.forEach((element) => {
        let brandList = []
        if (element.brands.length) {
          element.brands.forEach((item) => {
            brandList.push(item.name)
          })
        }
        element.brands = brandList
      })
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.hashid)
        })
      }
    },
    // 切换新版/旧版活动
    toggleEdition() {
      this.isNewEdition = this.isNewEdition ? 0 : 1
      let edition = this.isNewEdition ? '新版' : '旧版'
      this.$message.success(`切换${edition}活动成功`)
    },
    // 新增活动
    handleAddActivity() {
      // 判断是进新版本详情还是旧版本详情
      if (this.isNewEdition) {
        this.$router.push({
          name: 'AddActivityNew',
          params: { type: 'add', id: 0 },
        })
      } else {
        this.$router.push({
          name: 'AddActivity',
          params: { type: 'add', id: 0 },
        })
      }
    },

    // 点击下拉菜单
    async handleClickRow(value, row) {
      // 判断是否是线上活动，需要报名信息和排位设置

      if (!row.type) {
        this.$parent.$parent.hiddenTab('SignUpList')
        this.$parent.$parent.hiddenTab('Ranking')
        this.$parent.$parent.hiddenTab('ListConfig')
      } else {
        this.$parent.$parent.showTab('SignUpList')
        this.$parent.$parent.showTab('Ranking')
        this.$parent.$parent.showTab('ListConfig')
      }

      if (
        [
          'deleteRow',
          'ReductRow',
          'CancelActivity',
          'recoverActivity',
          'SignCode',
          'ShareRecords',
        ].includes(value)
      ) {
        this[value](row)
      } else {
        let params = {
          type: 'edit',
          id: row.hashid,
          create_step: row.create_step,
        }
        console.log(value, row)
        // 是否是新版详情跳转
        if (this.isNewEdition) {
          switch (value) {
            // 活动表单
            case 'SignUpList':
              this.$router.push({
                name: 'ActivityDetailNew',
                params,
                query: {
                  // 一级tab
                  activeTab: 'join',
                  // 二级tab
                  secondTab: 1,
                },
              })
              break
            // 发帖管理
            case 'ActivityPostList':
              this.$router.push({
                name: 'ActivityDetailNew',
                params,
                query: {
                  // 一级tab
                  activeTab: 'during',
                  // 二级tab
                  secondTab: 1,
                },
              })
              break
            // 发帖管理
            case 'ImageText':
              this.$router.push({
                name: 'ActivityDetailNew',
                params,
                query: {
                  // 一级tab
                  activeTab: 'during',
                  // 二级tab
                  secondTab: 0,
                },
              })
              break
            // 排位管理
            case 'Ranking':
              this.$router.push({
                name: 'ActivityDetailNew',
                params,
                query: {
                  // 一级tab
                  activeTab: 'before',
                  // 二级tab
                  secondTab: 3,
                },
              })
              break
            // 关联内容
            case 'Information':
              this.$router.push({
                name: 'ActivityDetailNew',
                params,
                query: {
                  // 一级tab
                  activeTab: 'info',
                  // 二级tab
                  secondTab: 4,
                },
              })
              break
            // 评论管理
            case 'SetComments':
              this.$router.push({
                name: 'ActivityDetailNew',
                params,
                query: {
                  // 一级tab
                  activeTab: 'during',
                  // 二级tab
                  secondTab: 2,
                },
              })
              break
            // 活动标签
            case 'ActivityTag':
              this.$router.push({
                name: 'ActivityDetailNew',
                params,
                query: {
                  // 一级tab
                  activeTab: 'before',
                  // 二级tab
                  secondTab: 2,
                },
              })
              break
          }
        } else {
          // 旧版详情跳转

          if (['SetComments', 'ActivityPostList'].includes(value)) {
            // params.topic_id =
            let res = await getCircleTopic({ activity_id: params.id })
            params.topic_id = res.data.id
          }
          this.$router.push({
            name: value,
            params,
          })
        }
      }
    },
    // 弹出签到码
    SignCode(row) {
      this.currentActivityId = row.hashid
      // console.log(this.currentActivityId)
      this.showCode = true
    },
    ShareRecords(row) {
      this.ShareRecordsDialogShow = true
      this.currentActivityId = row.hashid
    },
    // 取消活动
    CancelActivity(row) {
      console.log(row.hashid)
      this.currentActivityId = row.hashid
      this.openCancelDialog = true
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab === 'normal') {
          this.$msgbox
            .confirm('确定要将选中数据移入回收站吗？', '提示', {
              type: 'info',
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr })
            })
            .catch((err) => {})
        } else {
          this.$msgbox
            .confirm('确定要将选中数据永久删除吗？', '提示', {
              type: 'error',
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr })
            })
            .catch((err) => {})
        }
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab === 'normal') {
        this.$msgbox
          .confirm('确定要将此数据移入回收站吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.handleDelete({ id: row.hashid })
          })
          .catch((err) => {})
      } else {
        this.$msgbox
          .confirm('确定要将此数据永久删除吗？', '提示', {
            type: 'error',
          })
          .then((res) => {
            this.handleDelete({ id: row.hashid })
          })
          .catch((err) => {})
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true
      if (this.filterForm.tab === 'normal') {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg)
            this.reflashList()
          })
          .catch((err) => {
            this.loading = false
          })
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg)
            this.reflashList()
          })
          .catch((err) => {
            this.loading = false
          })
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm('确定要将选中数据还原吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr })
          })
          .catch((err) => {})
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm('确定要将此数据还原吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.Reduct({ id: row.hashid })
        })
        .catch((err) => {})
    },
    // 还原数据
    Reduct(id) {
      this.loading = true
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg)
          this.reflashList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 点击编辑
    editRow(row) {
      // 判断是进新版本详情还是旧版本详情
      if (this.isNewEdition) {
        // 新的
        this.$router.push({
          name: 'AddActivityNew',
          params: {
            type: 'edit',
            id: row.hashid,
            create_step: row.create_step,
          },
        })
      } else {
        this.$router.push({
          name: 'ActivityDetail',
          params: {
            type: 'edit',
            id: row.hashid,
            create_step: row.create_step,
          },
        })
      }

      // })
    },
    // 复制
    copyRow(row) {
      this.$prompt('请输入新活动名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(({ value }) => {
          copyActivityInfo({
            id: row.hashid,
            name: value,
          })
            .then((res) => {
              console.log(res)
              this.$message.success(res.msg)
              this.reflashList()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {})
    },
    // 取消发布
    withDrawRow(row) {
      withdraw({
        id: row.hashid,
      })
        .then((res) => {
          console.log(res)
          this.$message.success(res.msg)
          this.reflashList()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 发布
    postRow(row) {
      publish({ id: row.hashid })
        .then((res) => {
          // 0: 发布失败、1:发布成功
          if (!res.data.status) {
            // res.data.errors
            this.$message({
              // 将 message 就会被当作 HTML 片段处理
              dangerouslyUseHTMLString: true,
              message: res.data.errors.join('<br/><br/>'),
              type: 'error',
            })
          } else {
            this.$message.success(res.msg)
            this.reflashList()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name]
      } else {
        this.filterForm = {
          // 这个就是初始数据
          ...(this._filterForm
            ? JSON.parse(this._filterForm)
            : {
                start_time: -1, //开始时间
                end_time: -1, //结束时间
                user_keyword: '', //用户名
                activity_keyword: '', // 活动名称
                // brand_id: -1, //品牌Id
                status: -1, // 活动状态
                type: -1, // 活动类型
                sign_type: -1, //	报名类型
              }),

          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页多少条数据
        }
      }
      this.getActivityList(this.filterForm)
    },
    // 点击恢复活动状态
    recoverActivity(row) {
      this.$router.push({
        name: 'AddActivity',
        params: {
          type: 'recover',
          id: row.hashid,
          create_step: row.create_step,
        },
      })
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = column.prop
      this.getActivityList(this.filterForm)
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.getActivityList(this.filterForm)
    },
    // 刷新列表
    reflashList() {
      this.getActivityList({
        ...this.filterForm,
      })
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {}
      data.id = e.hashid
      data[key] = e[key]
      this.loading = true
      if (key === 'is_recommend') {
        // 更新推荐状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.reflashList()
          })
          .catch((err) => {
            this.reflashList()
          })
      } else if (key === 'is_show') {
        // 更新显示
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.reflashList()
          })
          .catch((err) => {
            this.reflashList()
          })
      } else {
        // 更新排序
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.reflashList()
          })
          .catch((err) => {
            this.reflashList()
          })
      }
    },
    // 分页查询
    changPage(e) {
      this.filterForm = { ...this.filterForm, ...e }
      this.getActivityList(this.filterForm)
    },
    //弹出举报记录
    reportRecord(id) {
      this.companyId = id
      this.openReportRecordDialog = true
    },
    //详情
    deleteDetail(row) {
      this.$message('暂无api与样式')
    },
  },
  created() {
    console.log(this.$store)
    const requestData = this.$route.query?.tab
      ? { ...this.filterForm, ...this.$route.query }
      : this.filterForm
    this.filterForm.tab = this.$route.query?.tab
      ? this.$route.query.tab
      : this.filterForm.tab
    // this.getActivityList(requestData) //获取列表数据
  },
  components: {
    ActivityCategoryMoveDialog,
    listFilter,
    Pagination,
    CancelActivity,
    PopoverInputEdit,
    ActivityConfig,
    ShareRecordsDialog,
  },
}
</script>

<style lang="scss" scoped>
.container {
  .body {
    padding: 20px;
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }

    .tool-tip {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
