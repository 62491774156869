<template>
  <el-dialog
    title="转移分类"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('CategoryName2')"
  >
    <el-form
      size="medium"
      :model="CategoryData"
      :rules="rules"
      ref="CategoryName2"
      label-width="95px"
      @submit.native.prevent
    >
      <el-form-item label="选择分类：" prop="ids">
        <el-select
          v-model="CategoryData.ids"
          :placeholder="optionsLoading ? '分类加载中...' : '请选择分类'"
          style="width: 80%"
          multiple
        >
          <el-option
            v-for="item in categoryOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
<!--        <p class="info">-->
<!--          <i class="el-icon-info"></i-->
<!--          >所选活动的分类-->
<!--        </p>-->
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit('CategoryName2')"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'activity-category-move-dialog',
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    categoryOptions: Array,
    Data: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      optionsLoading: false,
      rules: {
        ids: [{ required: true, message: '请选择分类', trigger: 'change' }],
      },
    }
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.CategoryData)
          this.$emit('submit', JSON.parse(JSON.stringify(this.CategoryData)))

          this.$refs[formName].resetFields()
          this.openDialog = false
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
      this.$refs[formName].resetFields()
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    CategoryData: {
      get() {
        return this.Data
      },
      set(val) {
        this.$emit('update: updateData', val)
      },
    },
  },
}
</script>

<style></style>
