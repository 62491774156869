<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    :onExport="onExport"
  >
    <el-form
      :model="form"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="用户名称：" prop="nickname">
          <el-input
            v-model="form.nickname"
            placeholder="请输用户名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="form.phone" placeholder="手机号搜索"></el-input>
        </el-form-item>
        <el-form-item label="所属架构：" prop="organization_id">
          <el-cascader
            v-model="form.organization_id"
            :options="jobList"
            :props="props"
            @change="handleExportCatalogChange"
            ref="CascaderRef"
            placeholder="架构/会内职务"
          >
          </el-cascader>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="邀请次数：" prop="count_type">
          <el-select v-model="form.count_type" >
            <el-option
              v-for="t in countTypeList"
              :key="t.value"
              :label="t.label"
              :value="t.value"
            />
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import {
  exportShareRecords
} from '../../../api/activity-list/share-records'
import { number } from 'echarts/core'

export default {
  name: 'filter',
  model: {
    prop: 'filterForm',
    event: 'updateFilter',
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
      default: () => ({}),
    },
    filterForm: {
      type: Object,
      default: () => ({}),
    },
    jobList: {
      type: Array,
      default:()=>[]
    },
    id: {
      type: [String, Number],
      default: '',
    },
    page:{
      type:[String, Number],
      default:1
    },
    export_ids:{
      type:Array,
      default:()=>[]
    }
  },
  data() {
    return {
      rules: {},
      props: {
        checkStrictly: true,
        expandTrigger: 'hover',
        label: 'name',
        value: 'id',
        emitPath: false,
      },

      countTypeList: [
        {
          value: -1,
          label: '全部',
        },
        {
          value: 0,
          label: '1-20',
        },
        {
          value: 1,
          label: '20-50',
        },
        {
          value: 2,
          label: '50-100',
        },
        {
          value: 3,
          label: '100以上',
        },
      ],
    }
  },
  methods: {
     // 关闭架构折叠面板
     handleExportCatalogChange() {
      this.$refs.CascaderRef.dropDownVisible = false
    },
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
    onExport() {
      const postData = { ...this.form,id:this.id, export_ids: this.export_ids, page: this.page }
      console.log(postData);
      exportShareRecords(postData).catch((err) => {})
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        console.log(this.filterForm)
        return this.filterForm
      },
      set(val) {
        this.$emit('updateFilter', val)
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
<style lang="scss" scoped>
::v-deep.el-input {
  width: auto;
}
</style>
