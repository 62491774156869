
import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

// 获取邀请列表
export const shareRecords = data => {
  return api({
    url: "/admin/cyc_activity/activity/shareRecords",
    method: "post",
    data
  });
};
// 获取上级组织列表
export const getOrganizationsTree = data => {
    return api({
      url: "/admin/cyc/Organization/OrganizationsTree",
      method: "post",
      data
    });
  };
  // 活动列表导出
export const exportShareRecords = data => {
    return fetchFile({
      url: "/admin/cyc_activity/activity/exportShareRecords",
      method: "post",
      data,
      download: true
    });
  };